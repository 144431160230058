<template>
  <div
    v-if="!segmentationActive"
    data-test="get-consult-from"
    class="b-help d-flex flex-column align-start relative"
  >
    <img
      class="b-help__img"
      loading="lazy"
      :src="
        imgUrl(
          'https://api.dominter.net/media/uploads/blog/helper3.png',
          disabledMobile ? 351 : 220,
          '',
        )
      "
      alt="helper"
    >
    <p class="headline-2 b-help__title relative">
      {{ title }}
    </p>
    <span class="title-2 b-help__subtitle relative">Честно и понятно ответим на все вопросы по провайдерам, тарифам,
      оборудованию и оплате</span>
    <d-btn
      data-test="get-consult-button"
      color="primary"
      class="b-help__btn title-4 relative"
      size="large"
      @click="showDialogCall"
    >
      Получить консультацию
    </d-btn>
  </div>
  <LazyVDialog
    v-model="showDialogSegmentation"
    :fullscreen="!disabledPortal"
    :width="disabledPortal ? 'auto' : ''"
  >
    <LazyModalCallMeSegmentation
      :provider="provider"
      @close="showDialogSegmentation = false"
      @show-call-dialog="connect"
    />
  </LazyVDialog>
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'

const props = defineProps({
  title: {
    type: String,
    default: 'Удобнее подобрать тариф со специалистом?',
  },
  wSegmentation: {
    type: Boolean,
  },
  provider: {},
})

const ctx = useNuxtApp()
const mainStore = useMainStore()
const disabledPortal = computed(() => mainStore.disabledPortal)
const disabledMobile = computed(() => mainStore.disabledMobile)
const segmentationActive = computed(() => mainStore.getSegmentationActive)
const showDialogSegmentation = ref(false)
const showDialogCall = () => {
  if (props.wSegmentation) showDialogSegmentation.value = true
  else connect()
}
const connect = () => {
  ctx.$event('callDialog', 'Получить консультацию')
}
const imgUrl = (path: string, w?: number, height?: number) => {
  const url = ctx.$thumborURL.setImagePath(path).resize(w, height).buildUrl()
  return url
}
</script>

<style scoped lang="scss">
.b-help {
  padding: 40px 224px 40px 40px;
  border-radius: 16px;
  border: 1px solid color(gray-darker);
  background: linear-gradient(92deg, #e7f1f8 0%, #fff 100%);
  overflow: hidden;
  @media (max-width: getBreakpoint(tablet)) {
    padding: 24px;
    border-radius: 12px;
  }
  @media (max-width: getBreakpoint(mobile-md)) {
    padding: 16px;
  }

  &__title {
    margin-bottom: 4px;
    @media (max-width: getBreakpoint(tablet)) {
      max-width: 450px;
    }
  }

  &__subtitle {
    @media (max-width: getBreakpoint(tablet)) {
      padding-right: 72px;
    }
  }
  &__btn {
    margin-top: 16px;
    padding: 16px 24px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-top: 16px;
      height: 44px;
      padding: 8px 16px;
      width: 100%;
      max-width: 480px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      height: 42px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      height: 40px;
    }
  }

  &__img {
    position: absolute;
    right: -17px;
    bottom: -43px;
    width: 274px;
    @media (max-width: getBreakpoint(tablet)) {
      width: 157px;
      bottom: 40px;
      right: -20px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      width: 132px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      width: 118px;
    }
  }
}
</style>
